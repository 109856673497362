// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'
import InboxsReducer from "./reducer/InboxsReducer"
import UsersReducer from "./reducer/UsersReducer"
import PromosReducer from "./reducer/PromosReducer"
import ApprovalsReducer from "./reducer/ApprovalsReducer"
import DriversReducer from "./reducer/DriversReducer"
import FinancesReducer from "./reducer/FinancesReducer"
import DownloadsReducer from "./reducer/DownloadsReducer"
import ReportsReducer from "./reducer/ReportsReducer"
import AboutsReducer from "./reducer/AboutsReducer"
import UploadsReducer from "./reducer/UploadsReducer"
import EinvoiceEmailsReducer from "./reducer/EinvoiceEmailsReducer"
import EinvoicesReducer from "./reducer/EinvoicesReducer"
import ManageCustomersReducer from "./reducer/ManageCustomersReducer"
import CheckingsReducer from "./reducer/CheckingsReducer"
import EmateraisReducer from "./reducer/EmateraisReducer"

import StageFunnelReducer from "./reducer/StageFunnelReducer"
import StageColdReducer from "./reducer/StageColdReducer"
import StageWarmReducer from "./reducer/StageWarmReducer"
import StageHotReducer from "./reducer/StageHotReducer"
import StageDecisionReducer from "./reducer/StageDecisionReducer"

import MasterFollowUpReducer from "./reducer/MasterFollowUpReducer"
import MasterInquiryReasonReducer from "./reducer/MasterInquiryReasonReducer"
import MasterLoseReasonReducer from "./reducer/MasterLoseReasonReducer"
import MasterMainstageReducer from "./reducer/MasterMainstageReducer"
import MasterMonthReducer from "./reducer/MasterMonthReducer"
import MasterSbuReducer from "./reducer/MasterSbuReducer"
import MasterStageReducer from "./reducer/MasterStageReducer"
import MasterSalesReducer from "./reducer/MasterSalesReducer"
import MasterStatusReducer from "./reducer/MasterStatusReducer"
import MasterWinLoseStatusReducer from "./reducer/MasterWinLoseStatusReducer"
import MasterYearReducer from "./reducer/MasterYearReducer"

const rootReducer = {
  auth,
  navbar,
  layout,
  dataInboxs: InboxsReducer,
  dataUsers: UsersReducer,
  dataPromos: PromosReducer,
  dataApprovals: ApprovalsReducer,
  dataAbouts: AboutsReducer,
  dataDrivers: DriversReducer,
  dataFinances: FinancesReducer,
  dataDownloads: DownloadsReducer,
  dataReports: ReportsReducer,
  dataUploads: UploadsReducer,
  dataEinvoiceEmails: EinvoiceEmailsReducer,
  dataEinvoices: EinvoicesReducer,
  dataManageCustomer: ManageCustomersReducer,
  dataChecking: CheckingsReducer,
  dataEmaterai: EmateraisReducer,
  dataFunnel: StageFunnelReducer,
  dataCold: StageColdReducer,
  dataWarm: StageWarmReducer,
  dataHot: StageHotReducer,
  dataDecision: StageDecisionReducer,
  dataFollowUp: MasterFollowUpReducer,
  dataInquiryReason: MasterInquiryReasonReducer,
  dataLoseReason: MasterLoseReasonReducer,
  dataMainStage: MasterMainstageReducer,
  dataMonth: MasterMonthReducer,
  dataSbu: MasterSbuReducer,
  dataStage: MasterStageReducer,
  dataSales: MasterSalesReducer,
  dataStatus: MasterStatusReducer,
  dataWinLoseStatus: MasterWinLoseStatusReducer,
  dataYear: MasterYearReducer

}

export default rootReducer
