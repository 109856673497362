import * as types from "../type/EinvoicesType"

const initialState = {
    einvoices: [],
    einvoices_sales: [],
    einvoice: {},
    view_start: null,
    view_end: null
}

const einvoicesReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_DATAS_EINVOICES:
            return {
                ...state,
                einvoices: action.payload.data,
                loading: false
            }
            case types.GET_DATAS_EINVOICES_START:
                return {
                    ...state,
                    view_start: action.payload,
                    loading: true
                }
            case types.GET_DATAS_EINVOICES_END:
                return {
                    ...state,
                    view_end: action.payload,
                    loading: false
                }    
        case types.GET_DATAS_EINVOICES_SALES:
            return {
                ...state,
                einvoices_sales: action.payload.data,
                loading: false
            }
        default:
            return state
    }
}

export default einvoicesReducer
