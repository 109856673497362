import * as types from "../type/StageColdType"

const initialState = {
    stagecolds: [],
    stagecold: {},
    notif: null,
    view_start: null,
    view_end: null
}

const stagecoldReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_DATAS_STAGECOLD:
            return {
                ...state,
                stagecolds: action.payload.data,
                loading: false
            }
        case types.GET_DATAS_STAGECOLD_START:
            return {
                ...state,
                view_start: action.payload,
                loading: true
            }
        case types.GET_DATAS_STAGECOLD_END:
            return {
                ...state,
                view_end: action.payload,
                loading: false
            }
        case types.UPDATE_DATAS_STAGECOLD:
            return {
                ...state,
                loading: false,
                notif: action.payload.data.result
            }
        default:
            return state
    }
}

export default stagecoldReducer
