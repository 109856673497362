import * as types from "../type/MasterSbuType"

const initialState = {
    master_sbus: []
}

const master_sbuReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_DATAS_MASTER_SBU:
            return {
                ...state,
                master_sbus: action.payload.data,
                loading: false
            }
        default:
            return state
    }
}

export default master_sbuReducer
