import * as types from "../type/MasterInquiryReasonType"

const initialState = {
    master_inquiryreasons: []
}

const master_inquiryreasonReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_DATAS_MASTER_INQUIRYREASON:
            return {
                ...state,
                master_inquiryreasons: action.payload.data,
                loading: false
            }
        default:
            return state
    }
}

export default master_inquiryreasonReducer
