import * as types from "../type/UploadsType"

const initialState = {
    uploads: [],
    uploads_sales: [],
    upload: {},
    view_start: null,
    view_end: null
}

const uploadsReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_DATAS_UPLOADS:
            return {
                ...state,
                uploads: action.payload.data,
                loading: false
            }
            case types.GET_DATAS_UPLOADS_START:
                return {
                    ...state,
                    view_start: action.payload,
                    loading: true
                }
            case types.GET_DATAS_UPLOADS_END:
                return {
                    ...state,
                    view_end: action.payload,
                    loading: false
                }    
            case types.GET_DATAS_UPLOADS_SALES:
            return {
                ...state,
                uploads_sales: action.payload.data,
                loading: false
            }
        default:
            return state
    }
}

export default uploadsReducer
