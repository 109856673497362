import * as types from "../type/DriversType"

const initialState = {
    drivers: [],
    driver: {},
    loading: true,
    notif_add: null,
    notif_update: {},
    notif_delete: {}
}

const DriversReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_DATAS_DRIVERS:
            return {
                ...state,
                drivers: action.payload,
                loading: false
            }
        case types.DELETE_DATAS_DRIVERS:
            return {
                ...state,
                notif_delete: action.payload.status,
                loading: false
            }
        case types.ADD_DATAS_DRIVERS:
            return {
                ...state,
                loading: false
            }
        case types.UPDATE_DATAS_DRIVERS:
            return {
                ...state,
                loading: false,
                notif_update: action.payload.status
            }
        case types.DETAIL_DATAS_DRIVERS:
            return {
                ...state,
                driver: action.payload,
                loading: false
            }
        case types.ADD_SUCCESS_DRIVERS:
            return {
                ...state,
                loading: false,
                notif_add: action.payload
            }
        case types.UPDATE_SUCCESS_DRIVERS:
            return {
                ...state,
                loading: false,
                notif_update: action.payload.status
            }
        case types.DELETE_SUCCESS_DRIVERS:
            return {
                ...state,
                loading: false,
                notif_delete: action.payload.status
            }
        default:
            return state
    }
}

export default DriversReducer
