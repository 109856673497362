import * as types from "../type/EinvoiceEmailsType"

const initialState = {
    einvoiceemails: [],
    einvoiceemails_sales: [],
    einvoiceemail: {},
    updatenotif: null,
    view_start: null,
    view_end: null
}

const einvoiceemailsReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_DATAS_EINVOICEEMAILS:
            return {
                ...state,
                einvoiceemails: action.payload.data,
                loading: false
            }
        case types.GET_DATAS_EINVOICEEMAILS_START:
            return {
                ...state,
                view_start: action.payload,
                loading: true
            }
        case types.GET_DATAS_EINVOICEEMAILS_END:
            return {
                ...state,
                view_end: action.payload,
                loading: false
            }
        case types.GET_DATAS_EINVOICEEMAILS_SALES:
            return {
                ...state,
                einvoiceemails_sales: action.payload.data,
                loading: false
            }
        case types.UPDATE_NOTIF_EINVOICEEMAILS:
            return {
                ...state,
                loading: false,
                updatenotif: action.payload.data.result
            }
        default:
            return state
    }
}

export default einvoiceemailsReducer
