import * as types from "../type/EmateraisType"

const initialState = {
    ematerais: [],
    ematerai: {},
    view_start: null,
    view_end: null
}

const emateraisReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_DATAS_EMATERAIS:
            return {
                ...state,
                ematerais: action.payload.data,
                loading: false
            }
            case types.GET_DATAS_EMATERAIS_START:
                return {
                    ...state,
                    view_start: action.payload,
                    loading: true
                }
            case types.GET_DATAS_EMATERAIS_END:
                return {
                    ...state,
                    view_end: action.payload,
                    loading: false
                }    
        default:
            return state
    }
}

export default emateraisReducer
