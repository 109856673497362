import * as types from "../type/PromosType"

const initialState = {
    promos: [],
    promo: {},
    loading: true,
    notif_add: null,
    notif_update: {},
    notif_delete: {}
}

const PromosReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_DATAS_PROMOS:
            return {
                ...state,
                promos: action.payload,
                loading: false
            }
        case types.DELETE_DATAS_PROMOS:
            return {
                ...state,
                notif_delete: action.payload.status,
                loading: false
            }
        case types.ADD_DATAS_PROMOS:
            return {
                ...state,
                loading: false
            }
        case types.UPDATE_DATAS_PROMOS:
            return {
                ...state,
                loading: false,
                notif_update: action.payload.status
            }
        case types.DETAIL_DATAS_PROMOS:
            return {
                ...state,
                promo: action.payload,
                loading: false
            }
        case types.ADD_SUCCESS_PROMOS:
            return {
                ...state,
                loading: false,
                notif_add: action.payload
            }
        case types.UPDATE_SUCCESS_PROMOS:
            return {
                ...state,
                loading: false,
                notif_update: action.payload.status
            }
        case types.DELETE_SUCCESS_PROMOS:
            return {
                ...state,
                loading: false,
                notif_delete: action.payload.status
            }
        default:
            return state
    }
}

export default PromosReducer
