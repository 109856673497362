import * as types from "../type/StageWarmType"

const initialState = {
    stagewarms: [],
    stagewarm: {},
    notif: null,
    view_start: null,
    view_end: null
}

const stagewarmReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_DATAS_STAGEWARM:
            return {
                ...state,
                stagewarms: action.payload.data,
                loading: false
            }
        case types.GET_DATAS_STAGEWARM_START:
            return {
                ...state,
                view_start: action.payload,
                loading: true
            }
        case types.GET_DATAS_STAGEWARM_END:
            return {
                ...state,
                view_end: action.payload,
                loading: false
            }
        case types.UPDATE_DATAS_STAGEWARM:
            return {
                ...state,
                loading: false,
                notif: action.payload.data.result
            }
        default:
            return state
    }
}

export default stagewarmReducer
