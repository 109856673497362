import * as types from "../type/ManageCustomersType"

const initialState = {
    managecustomers: [],
    managecustomer: {},
    sessionuser: [],
    sessionuseras3: [],
    loading: true,
    response_add: null,
    response_update: {},
    response_delete: {}
}

const ManageCustomersReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_DATAS_MANAGE_CUSTOMER:
            return {
                ...state,
                managecustomers: action.payload.data,
                loading: false
            }
        case types.DELETE_DATAS_MANAGE_CUSTOMER:
            return {
                ...state,
                response_delete: action.payload.status,
                loading: false
            }
        case types.ADD_DATAS_MANAGE_CUSTOMER:
            return {
                ...state,
                loading: false
            }
        case types.UPDATE_DATAS_MANAGE_CUSTOMER:
            return {
                ...state,
                loading: false,
                response_update: action.payload.status
            }
        case types.DETAIL_DATAS_MANAGE_CUSTOMER:
            return {
                ...state,
                managecustomer: action.payload.data[0],
                loading: false
            }
        case types.ADD_SUCCESS_MANAGE_CUSTOMER:
            return {
                ...state,
                loading: false,
                response_add: action.payload
            }
        case types.UPDATE_SUCCESS_MANAGE_CUSTOMER:
            return {
                ...state,
                loading: false,
                response_update: action.payload.status
            }
        case types.DELETE_SUCCESS_MANAGE_CUSTOMER:
            return {
                ...state,
                loading: false,
                response_delete: action.payload.status
            }
        case types.GET_VIEW_SESSION_USER:
            return {
                ...state,
                sessionuser: action.payload.data,
                loading: false
            }
        case types.GET_VIEW_SESSION_USER_AS3:
            return {
                ...state,
                sessionuseras3: action.payload.data,
                loading: false
            }
        default:
            return state
    }
}

export default ManageCustomersReducer
