import * as types from "../type/StageDecisionType"

const initialState = {
    stagedecisions: [],
    stagedecision: {},
    notif: null,
    view_start: null,
    view_end: null
}

const stagedecisionReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_DATAS_STAGEDECISION:
            return {
                ...state,
                stagedecisions: action.payload.data.data,
                loading: false
            }
        case types.GET_DATAS_STAGEDECISION_START:
            return {
                ...state,
                view_start: action.payload,
                loading: true
            }
        case types.GET_DATAS_STAGEDECISION_END:
            return {
                ...state,
                view_end: action.payload,
                loading: false
            }
        case types.UPDATE_DATAS_STAGEDECISION:
            return {
                ...state,
                loading: false,
                notif: action.payload.data.result
            }
        default:
            return state
    }
}

export default stagedecisionReducer
