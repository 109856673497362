import * as types from "../type/MasterStageType"

const initialState = {
    master_stages: []
}

const master_stageReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_DATAS_MASTER_STAGE:
            return {
                ...state,
                master_stages: action.payload.data,
                loading: false
            }
        default:
            return state
    }
}

export default master_stageReducer
