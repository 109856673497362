import * as types from "../type/ReportsType"

const initialState = {
    reports: [],
    report: {},
    loading: true,
    notif_add: null,
    notif_update: {},
    notif_delete: {}
}

const ReportsReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_DATAS_REPORTS:
            return {
                ...state,
                reports: action.payload,
                loading: false
            }
        case types.GET_DATAS_REPORTSA:
            return {
                ...state,
                reportsa: action.payload.data,
                loading: false
            }
        case types.GET_DATAS_REPORTSB:
            return {
                ...state,
                reportsb: action.payload.data,
                loading: false
            }
        case types.GET_DATAS_REPORTSC:
            return {
                ...state,
                reportsc: action.payload.data,
                loading: false
            }
        case types.DELETE_DATAS_REPORTS:
            return {
                ...state,
                notif_delete: action.payload.status,
                loading: false
            }
        case types.ADD_DATAS_REPORTS:
            return {
                ...state,
                loading: false
            }
        case types.UPDATE_DATAS_REPORTS:
            return {
                ...state,
                loading: false,
                notif_update: action.payload.status
            }
        case types.DETAIL_DATAS_REPORTS:
            return {
                ...state,
                report: action.payload,
                loading: false
            }
        case types.ADD_SUCCESS_REPORTS:
            return {
                ...state,
                loading: false,
                notif_add: action.payload
            }
        case types.UPDATE_SUCCESS_REPORTS:
            return {
                ...state,
                loading: false,
                notif_update: action.payload.status
            }
        case types.DELETE_SUCCESS_REPORTS:
            return {
                ...state,
                loading: false,
                notif_delete: action.payload.status
            }
        default:
            return state
    }
}

export default ReportsReducer
