import * as types from "../type/MasterYearType"

const initialState = {
    master_years: []
}

const master_yearReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_DATAS_MASTER_YEAR:
            return {
                ...state,
                master_years: action.payload.data,
                loading: false
            }
        default:
            return state
    }
}

export default master_yearReducer
