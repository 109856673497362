export const GET_DATAS_REPORTS = "GET_DATAS_REPORTS"
export const GET_DATAS_REPORTSA = "GET_DATAS_REPORTSA"
export const GET_DATAS_REPORTSB = "GET_DATAS_REPORTSB"
export const GET_DATAS_REPORTSC = "GET_DATAS_REPORTSC"
export const ADD_DATAS_REPORTS = "ADD_DATAS_REPORTS"
export const DETAIL_DATAS_REPORTS = "DETAIL_DATAS_REPORTS"
export const UPDATE_DATAS_REPORTS = "UPDATE_DATAS_REPORTS"
export const DELETE_DATAS_REPORTS = "DELETE_DATAS_REPORTS"

export const ADD_SUCCESS_REPORTS = "ADD_SUCCESS_REPORTS"
export const UPDATE_SUCCESS_REPORTS = "UPDATE_SUCCESS_REPORTS"
export const DELETE_SUCCESS_REPORTS = "DELETE_SUCCESS_REPORTS"