export const GET_DATAS_MANAGE_CUSTOMER = "GET_DATAS_MANAGE_CUSTOMER"
export const ADD_DATAS_MANAGE_CUSTOMER = "ADD_DATAS_MANAGE_CUSTOMER"
export const DETAIL_DATAS_MANAGE_CUSTOMER = "DETAIL_DATAS_MANAGE_CUSTOMER"
export const UPDATE_DATAS_MANAGE_CUSTOMER = "UPDATE_DATAS_MANAGE_CUSTOMER"
export const DELETE_DATAS_MANAGE_CUSTOMER = "DELETE_DATAS_MANAGE_CUSTOMER"

export const ADD_SUCCESS_MANAGE_CUSTOMER = "ADD_SUCCESS_MANAGE_CUSTOMER"
export const UPDATE_SUCCESS_MANAGE_CUSTOMER = "UPDATE_SUCCESS_MANAGE_CUSTOMER"
export const DELETE_SUCCESS_MANAGE_CUSTOMER = "DELETE_SUCCESS_MANAGE_CUSTOMER"

export const GET_VIEW_SESSION_USER = "GET_VIEW_SESSION_USER"
export const GET_VIEW_SESSION_USER_AS3 = "GET_VIEW_SESSION_USER_AS3"