import * as types from "../type/MasterMainStageType"

const initialState = {
    master_mainstages: []
}

const master_mainstageReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_DATAS_MASTER_MAINSTAGE:
            return {
                ...state,
                master_mainstages: action.payload.data,
                loading: false
            }
        default:
            return state
    }
}

export default master_mainstageReducer
