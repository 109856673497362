import * as types from "../type/UsersType"

const initialState = {
    users: [],
    user: {},
    loading: true,
    notif_add: null,
    notif_sendemail: null,
    notif_verification: null,
    notif_reset: null,
    notif_update: {},
    notif_delete: {},
    notif_logsession: {},
    sessionUser: localStorage.getItem("sessionUser"),
    sessionEmail: localStorage.getItem("sessionEmail"),
    sessionCustomer: localStorage.getItem("sessionCustomer"),
    sessionDepartemen: localStorage.getItem("sessionDepartemen"),
    sessionLevel: localStorage.getItem("sessionLevel")
}

const UsersReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_DATAS_USERS:
            return {
                ...state,
                users: action.payload,
                loading: false,
                state
            }
        case types.DELETE_DATAS_USERS:
            return {
                ...state,
                notif_delete: action.payload.status,
                loading: false
            }
        case types.ADD_DATAS_USERS:
            return {
                ...state,
                loading: false,
                notif_add: action.payload.data.result
            }
        case types.UPDATE_DATAS_USERS:
            return {
                ...state,
                loading: false,
                notif_update: action.payload.status
            }
        case types.DETAIL_DATAS_USERS:
            return {
                ...state,
                user: action.payload,
                loading: false
            }
        case types.ADD_SUCCESS_USERS:
            return {
                ...state,
                loading: false,
                notif_add: action.payload
            }
        case types.UPDATE_SUCCESS_USERS:
            return {
                ...state,
                loading: false,
                notif_update: action.payload.status
            }
        case types.DELETE_SUCCESS_USERS:
            return {
                ...state,
                loading: false,
                notif_delete: action.payload.status
            }
        case types.SEND_EMAIL_DATAS_USERS:
            return {
                ...state,
                notif_sendemail: action.payload.data.result
            }
        case types.VERIFICATION_PASSWORD_DATAS_USERS:
            return {
                ...state,
                notif_verification: action.payload.data.result
            }
        case types.RESET_PASSWORD_DATAS_USERS:
            return {
                ...state,
                notif_reset: action.payload.data.result
            }
        case types.GET_LOG_SESSION:
            return {
                ...state,
                notif_logsession: action.payload.data.result
            }


        default:
            return state
    }
}

export default UsersReducer
