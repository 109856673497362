export const GET_DATAS_USERS = "GET_DATAS_USERS"
export const ADD_DATAS_USERS = "ADD_DATAS_USERS"
export const DETAIL_DATAS_USERS = "DETAIL_DATAS_USERS"
export const UPDATE_DATAS_USERS = "UPDATE_DATAS_USERS"
export const DELETE_DATAS_USERS = "DELETE_DATAS_USERS"

export const ADD_SUCCESS_USERS = "ADD_SUCCESS_USERS"
export const UPDATE_SUCCESS_USERS = "UPDATE_SUCCESS_USERS"
export const DELETE_SUCCESS_USERS = "DELETE_SUCCESS_USERS"

export const SEND_EMAIL_DATAS_USERS = "SEND_EMAIL_DATAS_USERS"
export const VERIFICATION_PASSWORD_DATAS_USERS = "VERIFICATION_PASSWORD_DATAS_USERS"
export const RESET_PASSWORD_DATAS_USERS = "RESET_PASSWORD_DATAS_USERS"

export const GET_LOG_SESSION = "GET_LOG_SESSION"