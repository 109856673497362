import * as types from "../type/MasterWinLoseStatusType"

const initialState = {
    master_winlosestatuss: []
}

const master_winlosestatusReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_DATAS_MASTER_WINLOSESTATUS:
            return {
                ...state,
                master_winlosestatuss: action.payload.data,
                loading: false
            }
        default:
            return state
    }
}

export default master_winlosestatusReducer
