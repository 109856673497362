import * as types from "../type/ApprovalsType"

const initialState = {
    approvals: [],
    approval: {},
    loading: true,
    notif_add: null,
    notif_update: {},
    notif_delete: {}
}

const ApprovalsReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_DATAS_APPROVALS:
            return {
                ...state,
                approvals: action.payload,
                loading: false
            }
        case types.DELETE_DATAS_APPROVALS:
            return {
                ...state,
                notif_delete: action.payload.status,
                loading: false
            }
        case types.ADD_DATAS_APPROVALS:
            return {
                ...state,
                approvals: action.payload.data,
                loading: false
            }
        case types.UPDATE_DATAS_APPROVALS:
            return {
                ...state,
                loading: false,
                notif_update: action.payload.status
            }
        case types.DETAIL_DATAS_APPROVALS:
            return {
                ...state,
                approval: action.payload.data[0],
                loading: false
            }
        case types.ADD_SUCCESS_APPROVALS:
            return {
                ...state,
                loading: false,
                notif_add: action.payload
            }
        case types.UPDATE_SUCCESS_APPROVALS:
            return {
                ...state,
                loading: false,
                notif_update: action.payload.status
            }
        case types.DELETE_SUCCESS_APPROVALS:
            return {
                ...state,
                loading: false,
                notif_delete: action.payload.status
            }
        default:
            return state
    }
}

export default ApprovalsReducer
