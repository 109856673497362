import * as types from "../type/StageHotType"

const initialState = {
    stagehots: [],
    stagehot: {},
    notif: null,
    view_start: null,
    view_end: null
}

const stagehotReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_DATAS_STAGEHOT:
            return {
                ...state,
                stagehots: action.payload.data.data,
                loading: false
            }
        case types.GET_DATAS_STAGEHOT_START:
            return {
                ...state,
                view_start: action.payload,
                loading: true
            }
        case types.GET_DATAS_STAGEHOT_END:
            return {
                ...state,
                view_end: action.payload,
                loading: false
            }
        case types.UPDATE_DATAS_STAGEHOT:
            return {
                ...state,
                loading: false,
                notif: action.payload.data.result
            }
        default:
            return state
    }
}

export default stagehotReducer
