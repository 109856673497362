import * as types from "../type/CheckingsType"

const initialState = {
    checkings: [],
    checking_list: [],
    checking: {},
    updatenotif: null
}

const checkingsReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_DATAS_CHECKING:
            return {
                ...state,
                checkings: action.payload.data,
                loading: false
            }
        case types.GET_DATAS_CHECKING_LIST:
            return {
                ...state,
                checking_list: action.payload.data,
                loading: false
            }

        case types.DETAIL_DATAS_CHECKING:
            return {
                ...state,
                checking: action.payload.data,
                loading: false
            }
        case types.UPDATE_NOTIF_CHECKING:
            return {
                ...state,
                loading: false,
                updatenotif: action.payload.data.result
            }
        default:
            return state
    }
}

export default checkingsReducer
